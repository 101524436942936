.page-title-container {
    h1 {
        display: flex;
        align-items: center;
        color: $pageTitlePrimaryFontColor;
    }

    img {
        width: 16px;
        height: 11px;
        margin-left: 10px;
    }
    p {
        margin-top: 10px;
        color: $pageTitleSecondaryFontColor
    }
    h1,p {
        font-weight: 300;
    }
    hr {
        margin: 30px 0;
        height: 1px;
        border: none;
        color: $pageTitlePrimaryFontColor;
        background-color: $pageTitlePrimaryFontColor;
    }
}

.contrast-wcag {
    .page-title-container {
        h1 {
            color: $contrastWhite;
        }
        p {
            color: $contrastWhite;
        }
        hr {
            margin: 30px 0;
            height: 1px;
            border: none;
            color: $contrastWhite;
            background-color: $contrastWhite;
        }
    }
}