.select-items-section {
    label {
        color: $selectItemsTertiaryFontColor;
        font-weight: bold;
    }
    .select-wrapper {
        padding-top: 10px;
    }
    .box-select {
        display: flex;
        padding: 7px 0;
        width: 100% !important;
        @media( max-width: 600px ) {
            flex-direction: column;
        }
        .col-select {
            height: 200px;
            overflow-y: auto;
            width: 50%;
            margin: 10px 0 10px 15px;
            border: 1px solid $selectItemsBorderColor;
            @media( max-width: 600px ) {
                width: 100%;
                margin: 0;
            }
            &:nth-of-type(1) {
                margin: 10px 15px 10px 0;
            }
            .row-item {
                display: flex;
                width: 100%;
                position: relative;
                cursor: pointer;
                border: 2px solid transparent;
                &:focus-visible {
                    border-color: $selectItemsFocusColor;
                }
                &:hover {
                    .type, span {
                        background-color: $selectItemsBgColorHover;
                        color: $selectItemsSecondaryFontColor;
                    }
                }
                &.disabled {
                    cursor: default;
                    .type, span {
                        cursor: default;
                        color: $selectItemsDisableFontColor;
                    }
                }
                span {
                    display: flex;
                    align-items: center;
                    min-height: 30px;
                    padding: 10px 15px 10px 15px;
                    width: 100%;
                    cursor: pointer;
                    color: $selectItemsPrimaryFontColor;
                }
            }
            &.selected-items {
                &.error {
                    border: 1px solid $error;
                }
            }
        }
    }
}

.contrast-wcag {
    .select-items-section {
        .box-select {
            .col-select {
                border-color: $contrastWhite;
                .row-item {
                    &:focus-visible {
                        border-color: $contrastPrimary;
                    }
                    span {
                        color: $contrastWhite;
                    }
                    &.disabled {
                        span {
                            color: $contrastDisable;
                        }
                    }
                }
            }
        }
        label {
            color: $contrastPrimary;
        }
    }
}