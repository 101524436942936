.contact-form {
    .section-items {
        .contact-form-content {
            background-color: #ffffff;
            border: 1px solid $secondaryColor;
            padding: 50px 30px 20px 30px;
            position: relative;
            margin-bottom: 15px;
            @media( max-width: 600px ) {
                padding: 40px 15px 20px 15px;
            }
            @media( max-width: 400px ) {
                padding: 40px 5px 20px 5px;
            }
            &:first-of-type {
                margin-top: 30px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.contrast-wcag {
    .contact-form {
        .section-items {
            .contact-form-content {
                background-color: $contrastSecondary;
                border-color: $contrastWhite;
            }
        }
    }
}