.answer-card {
    background-color: #ffffff;
    padding: 20px 20px 10px 20px;
    position: relative;
    margin-bottom: 20px;
    border: 1px solid $chatCardColor;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:focus-within {
        border-color: $chatCardColorFocus;
    }
    @media( max-width: 640px ) {
        max-width: 350px;
    }
    &.answer-box {
        .actions {
            .action, span {
                padding: 5px;
                font-size: 15px;
            }
        }
    }
    
    &.opened {
        background-color: #fafafa;

        .actions {
            background-color: #fafafa;
        }
    }

    &.closed {
        background-color: #d9d9d9;

        .actions {
            background-color: #d9d9d9;
        }
    }

    .details {
        min-height: 75px;
    

        .det {
            margin-bottom: 7px;
            color: $chatTextColor;
            word-break: break-all;
            font-size: 1rem;

            span {
                font-weight: bold;
            }
        }
    }
    .actions {
        display: flex;
        position: relative;
        // justify-content: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #ffffff;

        .action, span {
            padding: 10px;
            text-align: center;
            max-width: 50%;
            width: 100%;
            cursor: pointer;
            transition: .2s ease-in-out all;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: 700;
            font-size: 1rem;
            color: $secondaryColor;
            width: max-content;
            border: 1px solid transparent;
            &:last-of-type {
                justify-content: flex-end !important;
            }
            
            &:focus-visible, &:focus-within {
                border-color: #000000;
            }

            &:hover {

                i {
                    transform: scale(.95);
                }
            }

            i {
                margin-left: 7px;
                transition: .2s ease-in-out all;
                
                
            }
            &.enter {

                i {
                    color: $chatCardColor;
                }
                
                
                &:hover {
                    color: $chatCardColor;
                }
            }

            &.delete {

                i {
                    color: $chatCardColor;
                }
                
                
                &:hover {
                    color: #bb2b1b;
                    
                    i {
                        color: #bb2b1b;
                    }
                }
            }

            &.see {

                i {
                    color: $chatCardColor;
                }
                
                
                &:hover {
                    color: $chatCardColor;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
        .download {
            display: flex;
            align-items: center;
        }
    }
}

.contrast-wcag {
    .autotests-answer {
        h2 {
            color: $contrastWhite;
        }
    }
    .answer-card {
        background-color: $contrastWhite;
        border-color: $contrastWhite;
        &:focus-within {
            border-color: $contrastPrimary;
        }
        &.answer-box {
            
            .actions {
                .action, span {
                    padding: 5px;
                    font-size: 15px;
                }
            }
        }
        
        &.opened {
            background-color: $contrastSecondary;
    
            .actions {
                background-color: $contrastSecondary;
            }
        }
    
        &.closed {
            background-color: $contrastSecondary;
    
            .actions {
                background-color: $contrastSecondary;
            }
        }
    
        .details {

            .det {
                color: $contrastWhite;
            }
        }
        .actions {
            background-color: $contrastSecondary;
    
            .action, span {
                color: $contrastPrimary;

                &:focus-visible, &:focus-within {
                    border: 1px solid $contrastWhite;
                }
                &.enter {
    
                    i {
                        color: $contrastPrimary;
                    }
                    
                    
                    &:hover {
                        color: $contrastPrimary;
                    }
                }
    
                &.delete {
    
                    i {
                        color: $contrastPrimary;
                    }
                    
                    
                    &:hover {
                        color: $contrastPrimary;
                        
                        i {
                            color: $contrastPrimary;
                        }
                    }
                }
    
                &.see {
    
                    i {
                        color: $contrastPrimary;
                    }
                    
                    
                    &:hover {
                        color: $contrastPrimary;
                    }
                }
    
            }
        }
    }

}