.tags-list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
    .tag {
        color: $secondaryColor;
        display: flex;
        align-items: center;
        border-radius: 25px;
        background-color: $btnSecondaryBg;
        padding: 6px 8px;
        margin: 5px;
        &:hover {
            svg {
                fill: $error;
            }
        }
        p {
            padding-right: 10px;
        }
        svg {
            transition: .3s;
            cursor: pointer;
        }
    }
}

.contrast-wcag {
    .tags-list {
        .tag {
            color: $btnSecondaryFont;
            &:hover {
                svg {
                    fill: $error;
                }
            }
            svg {
                fill: $btnSecondaryFont;
            }
        }
    }
}