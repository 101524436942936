@import url('https://fonts.googleapis.com/css?family=Material+Icons');

::-webkit-scrollbar {
    height: 6px; 
    width: 6px;
}
::-webkit-scrollbar-track {
    background: #ffffff; 
}
::-webkit-scrollbar-thumb {
    background: $scrollbar; 
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
    background: $scrollbar; 
}
::-moz-selection {
    background: $scrollbar; 
}
  
::selection {
    color: #ffffff;
    background: $scrollbar; 
}

* {
    box-sizing: border-box;
    outline: none;
}

html {
    font-size: 16px;
}

body {
    font-family: $fontPrimary;
    color: $background;
    overflow-x: hidden;
    margin: 0;
    min-height: 100vh;
}

.App {
    display: flex;
}

main {
    width: 100%;
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
}

.error-text {
    display: block;
    margin-top: 7px;
    color: $error;
    font-size: 0.875rem;
}

.container {
    position: relative;
    margin: 50px 55px;
    max-width: calc(100vw - 330px - 110px);
    @media( max-width: 1300px ) {
        margin: 50px 20px 50px 75px;
        // max-width: calc(100vw - 330px - 95px);
        // max-width: calc(100vw - 55px - 95px);
        max-width: 100%;
    }
}

.minimalize-menu {
    .container {
        max-width: calc(100vw - 55px - 110px);
        @media( max-width: 1300px ) {
            // max-width: calc(100vw - 55px - 95px);
            max-width: 100%;
        }
    }
}

a {
    text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p,a,strong {
    margin: 0;
}

.page-title {
    
    h2 {
        margin-top: 30px;
        margin-bottom: 50px;
        color: $secondaryColor;
    }

    hr {
        height:1px;
        border:none;
        color: $secondaryColor;
        background-color: $secondaryColor;
        margin-bottom: 45px;

    }
}

.skip-link {
    a {
        position: absolute;
        top: 0;
        left: 0px;
        color: $fourColor;
        border-bottom-right-radius: 16px;
        background: $primaryColor;
        -webkit-transition: top 1s ease-out;
        transition: top 1s ease-out;
        z-index: 9999999;
        padding: 10px;
        transform: translate(-100%, -100%);
        transition: .3s ease-in-out;
        &:focus-visible {
            position: absolute;
            left: 0px;
            top: 0px;
            outline-color: transparent;
            -webkit-transition: top .1s ease-in;
            transition: top .1s ease-in;
            transform: translate(0, 0);
        }
    }
}

.border {
    border: 1px solid #000000;
    padding: 15px 20px;
}

.contrast-wcag {
    .skip-link {
        a {
            color: $contrastWhite;
            background: $contrastSecondary;
        }
    }
    .page-title {
        h2 {
            color: $contrastWhite;
        }
    }
    background-color: $contrastSecondary;
    ::-webkit-scrollbar-track {
        background: #ffffff; 
    }
    ::-webkit-scrollbar-thumb {
        background: $scrollbarContrast; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: $scrollbarContrast; 
    }
    ::-moz-selection {
        background: $scrollbarContrast; 
    }
      
    ::selection {
        color: #ffffff;
        background: $scrollbarContrast; 
    }

    .border {
        border-color: $contrastWhite;
        padding: 15px 20px;
    }
}