.categories-section {
    .categories-wrapper {
        display: flex;
        
        @media( max-width: 900px ) {
            flex-direction: column;
        }
        .category-boxes {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
            position: relative;
            max-width: 600px;
            width: 100%;
            @media( max-width: 900px ) {
                margin: 0;
            }
            ::-webkit-scrollbar {
                width: 0.0001px;
            }
            ::-webkit-scrollbar-track {
                background: transparent; 
            }
            ::-webkit-scrollbar-thumb {
                background: transparent; 
            }
            ::-webkit-scrollbar-thumb:hover {
                background: transparent; 
            }
            ::-moz-selection {
                background: $scrollbar; 
            }
            ::selection {
                background: $scrollbar; 
            }
            .category {
                display: flex;
                flex-direction: column;
                // max-width: calc(33.3% - 30px);
                width: 100%;
                margin: 0 15px 15px 15px;
                border: 2px solid $categoriesBorder;
                box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                -webkit-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                -moz-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                background: #fff;
                max-height: 400px;
                overflow-y: auto;
                // @media( max-width: 1300px ) {
                //     max-width: calc(50% - 30px);
                // }
                @media( max-width: 900px ) {
                    max-width: 100%;
                    margin: 0 0 15px 0;
                }
                &.check {
                    border: 2px solid $categoriesBorderSelect;
                }
                    .header {
                        padding: 10px;
                        border-bottom: 2px solid $categoriesBorder;
                        position: relative;
                        cursor: pointer;
                        transition: .3s;
                        background: #f0f0f0;
                        &:hover {
                            background: #e0dede;
                        }
                        span {
                            font-weight: bold;
                            color: $categoriesFontColor;
                            font-size: 1rem;
                        }
                    }
                    .content {
                        padding: 10px;
                        .row-item {
                            padding-bottom: 5px;
                            margin-bottom: 10px;
                            border-bottom: 1px solid $categoriesBorder;
                            .header-item {
                                display: flex;
                                justify-content: space-between;
                                .svg-icon {
                                    cursor: pointer;
                                    &:hover {
                                        svg {
                                            fill: $error;
                                        }
                                    }
                                }
                                .category-input {
                                    border: none;
                                    outline: none;
                                    color: $categoriesFontColor;
                                    font-size: 0.75rem;
                                }
                            }
                        }
                    }
            }
        }
        .actions {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            max-width: 300px;
            @media( max-width: 900px ) {
                margin-left: 0;
            }
            .create-new-item {
                width: 100%;
                margin-bottom: 30px;
                border: 2px solid $categoriesBorder;
                box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                -webkit-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                -moz-box-shadow: 1px 3px 13px 0px rgba(0, 0, 0, 0.35);
                background: #ffffff;
                padding: 15px;
                height: max-content;
                .btn-wrap {
                    width: 100%;
                    max-width: 100%;
                }
                input {
                    margin-bottom: 15px;
                    width: 100%;
                    padding: 5px 10px;
                    border: 2px solid $categoriesBorder;
                    z-index: 999999;
                    outline: none;
                    color: $categoriesFontColor;
                    &.error {
                        border: 2px solid $error;
                    }
                }
                label {
                    display: block;
                    padding-bottom: 5px;
                    color: $categoriesFontColor;
                }
            }
            .save {
                width: 100%;
                // margin-left: 30px;
                .btn-wrap {
                    width: 100%;
                }
            }
        }
    }
}