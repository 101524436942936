.input-row {
    .upload-file {
        position: relative;
        &>div {
            &>div {
                justify-content: flex-start !important;
            }
        }
    }
    .avatar {
        margin-top: 15px;
    }
}