.calculator {
    label,small {
        display: block;
        margin-bottom: 8px;
        color: $contrastSecondary;
        font-weight: bold;
    }
    small {
        font-size: 12px;
        font-weight: lighter;
    }
    .table {
        .row {
            border-bottom: 2px dotted $error;
            display: flex;
            margin: 10px 0;
            @media( max-width: 870px ) {
                flex-direction: column;
                &:first-of-type {
                    flex-direction: row;
                    display: none;
                }
                .col {
                    max-width: 100% !important;
                    // &:first-of-type {
                    //     display: none;
                    // }
                }
            }
            @media( max-width: 600px ) {
                .col {
                    display: flex;
                    flex-direction: column;
                    input {
                        margin-left: 0 !important; 
                    }
                }
            }
            .col {
                color: $contrastSecondary;
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px; /* 150% */
                margin: 5px;
                margin-top: 0;
                @media( max-width: 870px ) {
                    display: flex;
                    align-items: center;
                    small {
                        margin-left: 5px;
                    }
                    input {
                        &:nth-of-type(2) {
                            margin-left: 10px;
                        }
                    }
                }
                &:nth-of-type(1) {
                    max-width: 40%;
                    width: 100%;
                    margin-left: 0;
                }
                &:nth-of-type(2), 
                &:nth-of-type(3) {
                    // max-width: 30%;
                    max-width: 60%;
                    width: 100%;
                }
                small {
                    display: block;
                    color: $contrastSecondary;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */
                }
            }
        }
    }
}

.contrast-wcag {
    .calculator {
        label {
            color: $contrastPrimary;
        }
        .table {
            .row {
                .col {
                    color: $contrastPrimary;
                    small {
                        color: $contrastPrimary;
                    }
                }
            }
        }
    }
}