.media-load {
    display: flex;
    flex-direction: column;
    position: relative;
    width: max-content;
    max-width: 100%;
    // &.error {
    //     .preview-cursor {
    //         border: 1px solid $error;
    //     }
    // }
    &.png, &.svg, &.com {
        .preview {
            background-image: url('./../../images/transparent-bg.jpg')
        }
    }
    &> i {
        color: $mediaLoaderFontColor;
        cursor: pointer;
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 10;
        border: 2px solid transparent;
        &:focus-visible {
            border-color: $mediaLoaderFocusColor;
        }
    }
    .preview {
        // margin: 15px 0;
        margin-top: 10px;
        max-height: 200px;
        max-width: 200px;
        width: 100%;
        height: auto;
        object-fit: contain;
        border: 2px solid transparent;
        &:focus-visible {
            border-color: $mediaLoaderFocusColor;
        }
    }
    .preview-cursor {
        cursor: pointer;
    }
    .btn-media {
        margin-top: 8px;
        display: flex;
        // justify-content: flex-end;
    }
    label {
        display: block;
        margin: 8px 0 0 0;
        color: #000000;
        font-weight: bold;
    }
    .media-popup {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 31;
        background: $mediaLoadRgbaColor;
        width: 100%;
        height: 100%;
        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            color: $mediaLoaderBtnFontColor;
            z-index: 10;
            cursor: pointer;
            font-weight: bold;
            font-size: 1.125rem;
            padding: 10px;
            background: $mediaLoaderBtnBgColor;
            border: 2px solid $mediaLoaderBorderColor;
            z-index: 99999999999;
            &:focus-visible {
                border-color: $mediaLoaderFocusColor;
            }
        }
        .images-media {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            background-color: $mediaLoaderBgColor;
            height: 450px;
            max-width: calc(100% - 100px);
            overflow-y: auto;
            z-index: 9999999;
            @media( max-width: 1000px ) {
                max-width: 95vw;
            }
            .image {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                width: 200px;
                height: 200px;
                margin: 10px;
                border: 2px solid $mediaLoaderBorderColor;
                padding: 5px;
                cursor: pointer;
                overflow-y: auto;
                &:focus-visible {
                    border-color: $mediaLoaderFocusColor;
                }
                &.active {
                    border-color: $mediaLoaderBorderSelect;
                }
                &.png, &.svg, &.com {
                    img {
                        background-image: url('./../../images/transparent-bg.jpg')
                    }
                }
                img {
                    max-width: 190px;
                    width: 100%;
                    max-height: 140px;
                    height: 100%;
                    object-fit: contain;
                    z-index: 99999999;
                    &.bg-svg {
                        background-image: url('./../../images/transparent-bg.jpg')
                    }
                }
                .simple-info-image {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 10px;
                    @media( max-width: 550px ) {
                        text-align: center;
                    }
                    .info-row {
                        margin-bottom: 5px;
                        @media( max-width: 1000px ) {
                            margin-bottom: 0;
                        }
                        &:last-of-type {
                            margin-bottom: 0;
                            border-bottom: none;
                            padding-bottom: 0;
                        }
                        span {
                            font-size: 1rem;
                            @media( max-width: 1000px ) {
                                font-size: 0.9rem;
                            }
                            @media( max-width: 550px ) {
                                font-size: 0.8rem;
                            }
                        }
                        .label {
                            font-weight: bold;
                            color: $mediaLoaderFontColor;
                        }
                        .value {
                            color: $mediaLoaderFontColor;
                        }
                    }
                }
            }
        }
    }
    .section-items {
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        overflow-y: auto;
        .gallery-content {
            border: 1px solid $secondaryColor;
            margin: 10px;
            max-height: calc(250px - 20px);
            max-width: calc(33.3% - 20px);
            min-width: 150px;
            width: 33.3%;
            height: 150px;
            &:last-of-type {
                margin-bottom: 0;
            }
            .section-index {
                margin: 0;
            }
            .images-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 5px;
                position: relative;
                img {
                    max-height: 130px;
                }
                & i {
                    color: $mediaLoaderFontColor;
                    cursor: pointer;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    z-index: 10;
                    border: 2px solid transparent;
                    &:focus-visible {
                        border-color: $mediaLoaderFocusColor;
                    }
                }
            }
        }
    }
}

.contrast-wcag {
    .media-load {
        .preview {
            &:focus-visible {
                border-color: $contrastPrimary;
            }
        }
        .media-popup {
            .images-media {
                background-color: $contrastSecondary;
                .image {
                    border-color: $contrastWhite;
                    &.active {
                        border-color: $mediaLoaderBorderSelect;
                    }
                    &:focus-visible {
                        border-color: $contrastPrimary;
                    }
                    .simple-info-image {
                        .info-row {
                            span {
                                color: $contrastPrimary;
                            }
                        }
                    }
                }
            }
            .close {
                &:focus-visible {
                    background-color: $contrastPrimary;
                    color: $contrastSecondary
                }
            }
        }
        &> i {
            color: $contrastWhite;
            &:focus-visible {
                border-color: $contrastWhite;
            }
        }
        .section-items {
            .gallery-content {
                border-color: $contrastWhite;
            }
        }
    }
}