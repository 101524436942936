.container-pagination {
    margin-top: 30px;
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media( max-width: 850px ) {
            flex-direction: column;
        }
        .center-actions {
            display: flex;
            justify-content: center;
            @media( max-width: 850px ) {
                margin: 15px 0;
            }
            @media( max-width: 550px ) {
                flex-direction: column;
            }
            .action {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media( max-width: 550px ) {
                    margin: 0 !important;
                    justify-content: center;
                    &:first-of-type {
                        margin-bottom: 15px;
                    }
                }
                p {
                    font-size: 1.125rem;
                    font-weight: bold;
                    color: $paginationFontColor;
                }
                .input-wrapper-component {
                    margin: 0 10px;
                    input {
                        width: 80px;
                    }
                    &.input-number {
                        input {
                            text-align: center;
                        }
                    }
                }
                &:first-of-type {
                    margin-right: 15px;
                    
                }
                &:last-of-type {
                    margin-left: 15px;
                }
            }
        }
    }
}

.contrast-wcag {
    .container-pagination {
        .wrapper {
            .center-actions {
                .action {
                    p {
                        color: $contrastWhite;
                    }
                }
            }
        }
    }
}