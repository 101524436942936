.pages-container {
    .wrapper {
        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $secondaryColor;
            padding-bottom: 35px;
            @media( max-width: 800px ) {
                flex-direction: column;
                .count {
                    margin-bottom: 15px;
                }
            }
            .count {
                
            }
            .btn-box {

            }
            &.xlsx {
                margin-bottom: 10px;
                .download {
                    display: flex;
                    align-items: center;
                    color: $pageTitleSecondaryFontColor;
                    text-decoration: underline;
                    cursor: pointer;
                    i {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.contrast-wcag {
    .wrapper {
        .actions {
            border-color: $contrastWhite;
            &.xlsx {
                .download {
                    color: $contrastWhite;
                }
            }
        }
    }
}