header {
    background-color: $headerBackgroundColor;
    transition: .3s;
    nav {
        margin: 0 55px;
        min-height: 80px;
        // overflow: hidden;
        @media( max-width: 1300px ) {
            margin: 0 20px 0 75px;
        }
        @media( max-width: 1100px ) {
            display: flex;
            min-height: 60px;
            max-height: 60px;
        }
        .nav-items {
            display: flex;
            justify-content: space-between;
            min-height: 80px;
            @media( max-width: 1100px ) {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-height: 50px;
                position: absolute;
                top: 60px;
                left: 0;
                background-color: $headerBackgroundColor;
                width: 100%;
                padding: 0 20px 0 75px;
                transform: translateY(-200%);
                transition: .25s ease-in-out transform;
                visibility:hidden;
                z-index: 2;
                &.active {
                    transform: translateY(0);
                    visibility: visible;
                }
            }
        }
        .wcag {
            display: flex;
            align-items: center;
            margin-right: 15px;
            @media( max-width: 1100px ) {
                margin-bottom: 15px;
                margin-right: 0;
            }
            @media( max-width: 500px ) {
                flex-direction: column;
                justify-content: center;
            }
            .contrast {
                display: flex;
                align-items: center;
                margin-right: 60px;
                // margin-left: -5px;
                outline: none;
                border: 2px solid transparent;
                // padding: 5px;
                height: max-content;
                @media( max-width: 1450px ) {
                    margin-right: 20px;
                }
                @media( max-width: 500px ) {
                    margin: 0;
                }
                &:focus-visible {
                    border-color: $headerFocusColor;
                }
            
                .icon {
                    display: flex;
                    .circle {
                        width: 26px;
                        height: 26px;
                        border: 4px solid $headerButtonColor;
                        border-radius: 100%;
                        background-color: #ffffff;
                        cursor: pointer;
                        transition: .25s ease-in-out all;
            
                        &.full {
                            background-color: $headerButtonColor;
                            transform: translateX(-10px);
                        }
                    }
            
                    
                }
                p {
                    color: $headerFontColor;
                    cursor: pointer;
                    font-size: 1.25rem;
                }
            
                // &.active {
                    
                //     .icon {
                //         .circle {
                //             border: 4px solid $contrastPrimary;
                            
                //             &.full {
                //                 transform: translateX(-13px);
                //                 background-color: $contrastSecondary;
                //             }
                //         }
                //     }
                //     p {
                //         color: $headerFontColor;
                //     }
                    
                // }
            }
            .font-property {
                display: flex;
                align-items: center;
                .font-icon {
                    display: flex;
                    cursor: pointer;
                    // padding: 5px;
                    border: 2px solid transparent;
                    svg {
                        width: 25px;
                        height: 25px;
                        .cls-3 {
                            fill: $headerButtonColor
                        }
                    }
                    &:focus-visible {
                        border-color: $headerFocusColor;
                    }
                }
                p {
                    font-size: 1.25rem;
                    color: $headerFontColor;
                    margin: 0 7px;
                    // padding: 5px;
                    border: 2px solid transparent;
                    cursor: pointer;
                    &:focus-visible {
                        border-color: $headerFocusColor;
                    }
                }
            }
        }
        .options {
            display: flex;
            align-items: center;
            margin-left: 15px;
            @media( max-width: 1100px ) {
                margin-left: 0;
                padding-bottom: 15px;
            }
            @media( max-width: 700px ) {
                flex-direction: column;
            }
            .cache {
                @media (max-width: 700px) {
                    margin-bottom: 15px;
                }
            }
            .language {
                margin: 0 30px;
                @media( max-width: 1450px ) {
                    margin: 0 15px;
                }
                @media (max-width: 700px) {
                    margin: 0 0 15px 0;
                }
                &.min-width {
                    min-width: 200px;
                }
            }
            .hello {
                color: $headerFontColor;
                font-size: 1.25rem;
                font-weight: 500;
                @media (max-width: 700px) {
                    margin-bottom: 10px;
                }
            }
            .logout {
                margin-left: 15px;
                @media (max-width: 700px) {
                    margin: 0;
                }
                a {
                    cursor: pointer;
                    i {
                        color: $headerFontColor;
                        border: 2px solid transparent;
                        &:focus-visible {
                            border-color: $btnFocusColor;
                        }
                    }
                }
            }
        }
        .mobile-hamburger {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            @media (min-width: 1100px) {
                display: none;
            }
            .hamburger-wrap {
                @media (min-width: 1100px) {
                    display: none;
                    overflow: hidden;
                }
                position: relative;
                z-index: 2;
                transition: .3s ease-in-out all;
                padding: 0 5px;
                border: 2px solid transparent;
                // margin: -7px;
                &:focus-visible {
                    border-color: $btnFocusColor;
                }
                .hamburger .line{
                    width: 45px;
                    height: 5px;
                    background-color: $headerHamburgerColor; 
                    display: block;
                    margin: 8px auto;
                    -webkit-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                  }
                  
                  .hamburger:hover{
                    cursor: pointer;
                  }
            
                  .hamburger{
                    -webkit-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                  }
                  
                  .hamburger.is-active{
                    animation: smallbig 0.6s forwards;
                  }
                  
                  @keyframes smallbig{
                    0%, 100%{
                      -webkit-transform: scale(1);
                      -ms-transform: scale(1);
                      -o-transform: scale(1);
                      transform: scale(1);
                    }
                  
                    50%{
                      -webkit-transform: scale(0);
                      -ms-transform: scale(0);
                      -o-transform: scale(0);
                      transform: scale(0);
                    }
                  }
                  
                  .hamburger.is-active .line:nth-child(1),
                  .hamburger.is-active .line:nth-child(2),
                  .hamburger.is-active .line:nth-child(3){
                    -webkit-transition-delay: 0.2s;
                    -o-transition-delay: 0.2s;
                    transition-delay: 0.2s;
                  }
                  
                  .hamburger.is-active .line:nth-child(2){
                    opacity: 0;
                  }
                  
                  .hamburger.is-active .line:nth-child(1){
                    -webkit-transform: translateY(13px) rotate(45deg);
                    -ms-transform: translateY(13px) rotate(45deg);
                    -o-transform: translateY(13px) rotate(45deg);
                    transform: translateY(13px) rotate(45deg);
                  }
                  
                  .hamburger.is-active .line:nth-child(3){
                    -webkit-transform: translateY(-13px) rotate(-45deg);
                    -ms-transform: translateY(-13px) rotate(-45deg);
                    -o-transform: translateY(-13px) rotate(-45deg);
                    transform: translateY(-13px) rotate(-45deg);
                  }
                  
            }
        }
    }
}

.contrast-wcag {
    header {
        background-color: $contrastWhite;
        nav {
            .wcag {
                .contrast {
                    .icon {
                        .circle {
                            border: 4px solid $contrastPrimary;
                            
                            &.full {
                                transform: translateX(-13px);
                                background-color: $contrastSecondary;
                            }
                        }
                    }
                    p {
                        color: $contrastSecondary;
                    }
                }
                .font-property {
                    p {
                        color: $contrastSecondary;
                    }
                    .font-icon {
                        svg {
                            .cls-3 {
                                fill: $contrastSecondary
                            }
                        }
                    }
                }
            }
            .options {
                .cache {
                    .btn-wrap {
                        &:focus-visible {
                            border-color: $contrastSecondary;
                        }
                        .btn {
                            &.btn-primary {
                                &:hover {
                                    background-color: $contrastSecondary !important;
                                    color: $contrastWhite;
                                }
                            }
                        }
                    }
                }
                .language {
                    .select-wrap {
                        .select-wrapper {
                            &:focus-visible, &:focus-within {
                                border-color: $contrastSecondary !important;
                            }
                            .select {
                                &.btn-primary, &.btn-secondary {
                                    background-color: $contrastPrimary;
                                    color: $contrastSecondary;
                                    &:hover {
                                        background-color: $contrastSecondary !important;
                                        color: $contrastWhite;
                                        .list {
                                            background-color: $contrastSecondary !important;
                                            li {
                                                color: $contrastWhite;
                                            }
                                        }
                                        .arrow {
                                            svg {
                                                path {
                                                    fill: $contrastWhite;
                                                }
                                            }
                                        }
                                    }
                                    .list {
                                        background-color: $contrastPrimary;
                                        li {
                                            color: $contrastSecondary;
                                        }
                                    }
                                    .arrow {
                                        svg {
                                            path {
                                                fill: $contrastSecondary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .hello {
                    color: $contrastSecondary;
                }
                .logout {
                    i {
                        color: $contrastSecondary;
                    }
                }
            }
            .nav-items {
                background-color: $contrastWhite;
            }
        }
    }
}