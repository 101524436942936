.box-header-slider {
    .section-items {
        .box-header-slider-content {
            background-color: #ffffff;
            border: 1px solid $secondaryColor;
            padding: 50px 30px 20px 30px;
            position: relative;
            margin-bottom: 15px;
            &:first-of-type {
                margin-top: 30px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.contrast-wcag {
    .box-header-slider-slider {
        .section-items {
            .box-header-slider-content {
                background-color: $contrastSecondary;
            }
        }
    }
}