.link-component {
    label {
        display: block;
        margin: 8px 0;
        color: $editorLabelFontColor;
        font-weight: bold;
    }
}

.contrast-wcag {
    .link-component {
        label {
            color: $contrastPrimary;
        }
        .border {
            border-color: $contrastWhite;
            padding: 15px 20px;
        }
    }
}