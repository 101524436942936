.translate-container {
    .wrapper {
        .form {
            .input-row {
                margin-bottom: 15px;
            }
            .input-save {
                margin-top: 20px;
            }
        }
    }
}