.children {
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
}
.page-item {
    &.active {
        .page-details {
            opacity: 1;
            height: auto;
            padding: 35px 15px 15px 15px;
            margin-bottom: 0;
        }
        .header-item {
        }
        &+.children {
            opacity: 1;
            height: auto;
            padding: 0 0 0 20px;
            visibility: visible;
        }
    }
    .header-item {
        display: flex;
        justify-content: space-between;
        padding: 12px 55px 12px 25px;
        position: relative;
        cursor: pointer;
        transition: .3s;
        border: 2px solid $menusBorderColor;
        color: $menusPrimaryFontColor;
        border-radius: 30px;
        z-index: 1;
        &:focus-visible {
            border-color: $menusFocusColor;
        }
        &:hover {
            background: $menusPrimaryBgColorHover;
        }
        .svg-icon {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: rotate(-90deg) translateX(100%);
        }
        span {
            display: block;
            font-weight: bold;
        }
        small {
            font-size: 16px;
            color: $menusSecondaryFontColor;
        }
    }
    .page-details {
        position: relative;
        top: -20px;
        opacity: 0;
        height: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        border: 1px solid $menusBorderColor;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top: none;
        .target {
            display: flex;
            align-items: center;
            margin: 10px 0;
            z-index: 99999999999;
            width: max-content;
            border: 2px solid transparent;
            &:focus-within {
                border-color: $menusFocusColor;
            }
            label {
                color: $menusSecondaryFontColor;
                margin: 0;
                margin-left: 10px;
                &:before {
                    content: none;
                }
            }
            .target-checkbox {
                display: inline-block;
                border-radius: 4px;
                height: 14px;
                width: 14px;
                margin: 0;
            }
        }
        label {
            color: $menusSecondaryFontColor;
            margin: 10px 0;
        }
        .actions {
            display: flex;
            justify-content: space-between;
            z-index: 9999999999;
            margin: 0 5px;
        }
    }
}

.contrast-wcag {
    .page-item {
        .page-details {
            label {
                color: $contrastWhite;
                &::before {
                    background-color: $contrastWhite;
                }
            }
            .target {
                &:focus-within {
                    border-color: $contrastWhite;
                }
                label {
                    color: $contrastWhite;
                }
            }
            .actions {
                .add, .remove {
                    color: $contrastWhite;
                    &::before {
                        background-color: $contrastWhite;
                    }
                    &:focus-visible {
                        border-color: $contrastWhite;
                    }
                }
            }
        }
    }
}