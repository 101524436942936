.welcome-container {
    .information-box {
        display: flex;
        justify-content: flex-end;
        position: relative;
        background-color: $secondaryColor;
        padding: 45px 65px;
        margin-top: 60px;
        @media( max-width: 600px ) {
            padding: 45px 30px;
        }
        .info {
            position: absolute;
            left: 80px;
            top: -40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primaryColor;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            p {
                font-size: 3rem;
                color: $secondaryColor;
                font-weight: bold;
            }
        }
        .text {
            p {
                font-size: 1.5rem;
            }
        }
    }
}

.contrast-wcag {
    .information-box {
        background-color: $contrastWhite;
        .text {
            p {
                color: $contrastSecondary;
            }
        }
        .info {
            background-color: $contrastPrimary;
            p {
                color: $contrastSecondary;
            }
        }
    }
}