.editor {
    .ck-editor__editable, .ql-editor {
        min-height: 250px;
    }
    .ck-editor__top {
        height: 40px;
        .ck-toolbar__items {
            height: 40px;
        }
    }
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    label {
        display: block;
        margin-bottom: 8px;
        color: $editorLabelFontColor;
        font-weight: bold;
    }
    p {
        margin-bottom: 8px;
        color: $editorLabelFontColor;
    }
}

.tox {
    .tox-dialog__foter-end {
        button {
            background-color: red;
        }
    }
}

.contrast-wcag {
    .editor {
        label, p {
            color: $contrastPrimary;
        }
    }
}