.gallery {
    .section-items {
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        overflow-y: auto;
        .gallery-content {
            border: 1px solid $secondaryColor;
            margin: 10px;
            max-height: calc(250px - 20px);
            max-width: calc(33.3% - 20px);
            min-width: 150px;
            width: 33.3%;
            height: 150px;
            &:last-of-type {
                margin-bottom: 0;
            }
            .section-index {
                margin: 0;
            }
            .images-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 5px;
                position: relative;
                img {
                    max-height: 130px;
                }
                & i {
                    color: $mediaLoaderFontColor;
                    cursor: pointer;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    z-index: 10;
                    border: 2px solid transparent;
                    &:focus-visible {
                        border-color: $mediaLoaderFocusColor;
                    }
                }
            }
        }
    }
}

.contrast-wcag {
    .gallery {
        .section-items {
            .gallery-content {
                border-color: $contrastWhite;
            }
        }
    }
}