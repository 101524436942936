//DEFAULT
$background: #ffffff;
$error: #d32f2f;
$scrollbar: #000000;
$scrollbarContrast: #ffdb47;
$contrastPrimary: #ffdb47;
$contrastSecondary: #000000;
$contrastWhite: #ffffff;
$contrastDisable: #928e8e;
$contrastNavbarBackgroundGradientColor: #9c800e;

$loaderRgbaColor: rgba(255, 255, 255, .5);
//HEADER
$headerBackgroundColor: #d9d9d9;
$headerFontColor: #000000;
$headerButtonColor: #000000;
$headerFocusColor: #000000;
$headerHamburgerColor: #000000;
//NAVBAR
// $navbarBackgroundColor: #000000;
// $navbarBackgroundGradientColor: #575555;
// $navbarFontColor: #ffffff;
// $navbarIconsColor: #ffffff;
// $navbarButtonColor: #ffdb47;
// $navbarBorderColor: #ffffff;
// $navbarFontColorHover: #ffdb47;
// $navbarFocusColor: #ffffff;
$navbarBackgroundColor: #d9d9d9;
$navbarBackgroundGradientColor: #d9d9d9;
$navbarFontColor: #000000;
$navbarIconsColor: #ffffff;
$navbarButtonColor: #000000;
$navbarBorderColor: #000000;
$navbarFontColorHover: #e80808;
$navbarFocusColor: #000000;
//BUTTON, SELECT, INPUT
$btnPrimaryBg: #000000;
$btnPrimaryBgHover: #000000;
$btnPrimaryFont: #ffffff;
$btnPrimaryFontHover: #ffdb47;
$btnSecondaryBg: #ffdb47;
$btnSecondaryBgHover: #000000;
$btnSecondaryFont: #000000;
$btnSecondaryFontHover: #ffdb47;
$btnTertiaryFont: #474747;
$btnTertiaryBorder: #474747;
$btnFocusColor: #000000;
$btnPrimaryBorder: #000000;
$btnBlockBg: #d9d9d9;
$btnBlockFont: #474747;
//INFORMATION CARD
$informationFontColor: #000000;
//TABLE
$tablePrimaryFontColor: #000000;
$tableSecondaryFontColor: #474747;
$tableBorderColor: #000000;
$tableBackgroundColor: #f4f4f4;
$tableFocusColor: #000000;
//PAGINATION
$paginationFontColor: #000000;
//LOADER
$loaderBorderColor: #000000;
// ChatCard
$chatCardColor: #000000;
$chatTextColor: #474747;
$chatCardColorFocus: #000000;
// LIVE CHAT
$liveChatBackgroundColor: #000000;
$liveChatTextColor: #474747;
//POPUP
$popupBg: rgba(24, 25, 24, 0.86);
$popupHeaderBg: #d9d9d9;
$popupContentBg: #ffffff;
$popupContentFontColor: #000000;
$popupCloseFontColor: #000000;
$popupFocusColor: #000000;
//TITLE
$titleContentFontColor: #000000;
$titleBorderColor: #000000;
//MEDIA LIBRARY
$mediaBorderColor: #000000;
$mediaPrimaryFocusColor: #000000;
$mediaFontColor: #474747;
$mediaPopupRgbaColor: rgba(24, 25, 24, 0.86);
$mediaPopupBgColor: #ffffff;
$mediaFocusColor: #000000;
//MENUS
$menusPrimaryFontColor: #000000;
$menusSecondaryFontColor: #474747;
$menusTertiaryFontColor: #ffdb47;
$menusPrimaryBgColor: #f4f4f4;
$menusSecondaryBgColor: #fdfdfd;
$menusBorderColor: #474747;
$menusPrimaryBgColorHover: #e0dede;
$menusButtonColorHover: #000000;
$menusFocusColor: #000000;
//SELECT ITEMS
$selectItemsPrimaryFontColor: #474747;
$selectItemsSecondaryFontColor: #ffffff;
$selectItemsTertiaryFontColor: #000000;
$selectItemsBgColorHover: #474747;
$selectItemsBorderColor: #000000;
$selectItemsDisableFontColor: #d6d6d6;
$selectItemsFocusColor: #000000;
//MEDIA ITEMS
$mediaLoadRgbaColor: rgba(36, 35, 35, 0.7);
$mediaLoaderBorderSelect: rgb(11, 211, 11);
$mediaLoaderBorderColor: #000000;
$mediaLoaderFontColor: #474747;
$mediaLoaderBtnFontColor: #000000;
$mediaLoaderBtnBgColor: #ffffff;
$mediaLoaderBgColor: #ffffff;
$mediaLoaderFocusColor: #000000;
//SETTINGS
$settingsHeaderBgColor: #f4f4f4;
$settingsHeaderBgColorHover: #e0dede;
$settingsBorderColor: #000000;
$settingsPrimaryFontColor: #000000;
$settingsSecondaryFontColor: #474747;
$settingsFocusColor: #000000;
//PAGE TITLE
$pageTitlePrimaryFontColor: #000000;
$pageTitleSecondaryFontColor: #474747;
//CHECKBOX
$checkboxBorderColor: #000000;
$checkboxFontColor: #474747;
$checkboxFocusColor: #000000;
//EDITOR
$editorLabelFontColor: #000000;
//BOXES
$boxesPrimaryColor: #000000;
$boxesFocusColor: #000000;
$boxesNewColor: #d9d9d9;
//NOTIFICATION ALERT
$notiBgColor: #ffffff;
$notiPrimaryFontColor: #474747;
$notiSecondaryFontColor: #000000;
//CONTENT
$primaryFontColor: #474747;
$primaryColor: #ffdb47;
$secondaryColor: #000000;
$focusColor: #000000;
$tertiaryColor: #ffffff;
$fourColor: #000000;
//CATEGORIES
$categoriesBorderSelect: rgb(11, 211, 11);
$categoriesBorder: #d8d8d8;
$categoriesFontColor: #000000;
// NewUser
$PermissionsFontColor: #474747;
