.settings-box {
    border: 1px solid $boxesPrimaryColor;
    margin-bottom: 20px;
    .header {
        padding: 20px 30px;
        background-color: $boxesNewColor;
        span {
            font-weight: bold;
            color: $boxesPrimaryColor;
        }
    }
    &.versions-page {
        .content {
            a, p {
                display: block;
                color: $boxesPrimaryColor;
                margin-bottom: 10px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                border-bottom: 1px solid $boxesPrimaryColor;
            }
        }
    }
    .content {
        padding: 20px 30px;
        .react-datetime-picker {
            text-align: center;
            display: block;
        }
        .react-datetimerange-picker {
            display: block;
        }
        .media-load {
            margin: 0 auto;
            max-width: 200px;
            max-height: 200px;
        }
        .actual {
            padding-bottom: 5px;
            margin-bottom: 10px;
            border-bottom: 1px solid $boxesPrimaryColor;
            span {
                font-weight: bold;
                color: $boxesPrimaryColor;
            }
            img {
                margin-left: 15px;
                width: 16px;
                height: 11px;
            }
        }
        .other {
            span {
                font-weight: bold;
                display: block;
                margin-bottom: 15px;
                color: $boxesPrimaryColor;
            }
            .languages-items {
                display: flex;
                flex-direction: column;
                .language {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    img {
                        width: 16px;
                        height: 11px;
                    }
                    .icon {
                        display: flex;
                        border: 2px solid transparent;
                        &:focus-visible {
                            border-color: $boxesFocusColor;
                        }
                        svg {
                            margin: 0 10px;
                            width: 15px;
                            height: 15px;
                            cursor: pointer;
                        }
                    }
                    span {
                        margin: 0;
                    }
                }
            }
        }
        .new-tag {
            position: relative;
            .submit {
                margin-top: 10px;
            }
            .result {
                position: absolute;
                left: 0;
                max-height: 64px;
                width: 100%;
                overflow-y: auto;
                z-index: 99999;
                .result-choose {
                    position: relative;
                    border: 2px solid $boxesPrimaryColor;
                    padding: 5px 10px;
                    background: #ffffff;
                    cursor: pointer;
                    p {
                        color: $boxesPrimaryColor;
                    }
                    &:focus-visible {
                        border-color: $boxesFocusColor;
                    }
                }
            }
        }
        small {
            display: block;
            margin-top: 5px;
            color: $boxesPrimaryColor;
        }
        .tags {
            display: flex;
            flex-wrap: wrap;
            .tag {
                position: relative;
                border: 1px solid $boxesPrimaryColor;
                margin: 5px;
                padding: 5px;
                cursor: pointer;
                overflow: hidden;
                min-width: 30px;
                min-height: 30px;
                &:focus-visible {
                    border-color: $boxesFocusColor;
                    p {
                        font-weight: bold;
                    }
                }
                p {
                    text-align: center;
                    color: $boxesPrimaryColor;
                }
                .remove {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    // transition: .1s;
                    width: 20px;
                    height: 20px;
                    z-index: 999999;
                    // transition-delay: .2s;
                }
                &:hover {
                    &:after {
                        transform: translate(0, 0);
                    }
                    .remove {
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #ffffff;
                    transform: translate(100%, -100%);
                    // transition: .2s;
                }
            }
        }
        .react-datetimerange-picker__wrapper {
            max-width: 100%;
            height: 100%;
            flex-wrap: wrap;
            padding: 5px;
            border-color: $boxesPrimaryColor;
            .react-datetimerange-picker__button {
                &:hover {
                    svg {
                        stroke: $boxesPrimaryColor;
                    }
                }
            }
        }
        .react-datetime-picker__wrapper {
            border-color: $boxesPrimaryColor;
            .react-datetime-picker__button {
                &:hover {
                    svg {
                        stroke: $boxesPrimaryColor;
                    }
                }
            }
        }
        .react-datetime-picker__clear-button  {
            display: none;
        }
        .react-calendar__month-view__weekdays__weekday {
            color: #000000;
        }
        .download {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 15px;
            cursor: pointer;
            color: #000000;
            p {
                text-decoration: underline;
                margin-right: 5px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.contrast-wcag {
    .settings-box {
        border-color: $contrastWhite;
        .header {
            background-color: $contrastPrimary;
            span {
                color: $contrastSecondary;
            }
        }
        &.versions-page {
            .content {
                a, p {
                    color: $contrastPrimary;
                    border-color: $contrastPrimary;
                }
            }
        }
        .content {
            .download {
                color: $contrastPrimary;
            }
            .react-datetime-picker__wrapper {
                border-color: $contrastWhite;
                .react-datetime-picker__inputGroup__input {
                    color: $contrastWhite;
                }
                .react-datetime-picker__button {
                    svg {
                        stroke: $contrastWhite;
                    }
                }
            }
            .react-datetimerange-picker__wrapper {
                border-color: $contrastWhite;
                .react-datetimerange-picker__inputGroup__input {
                    color: $contrastWhite;
                }
                .react-datetimerange-picker__button {
                    color: $contrastWhite;
                    svg {
                        stroke: $contrastWhite;
                    }
                }
            }
            .actual {
                border-color: $contrastWhite;
                span {
                    color: $contrastPrimary;
                }
            }
            .other {
                .languages-items {
                    .language {
                        .icon {
                            svg {
                                fill: $contrastPrimary;
                            }
                            &:focus-visible {
                                border-color: $contrastWhite;
                            }
                        }
                        span {
                            color: $contrastPrimary;
                        }
                    }
                }
            }
            small {
                color: $contrastWhite;
            }
            .checkbox-wrapper  {
                .accept {
                    .check {
                        border-color: $contrastWhite;
                        svg {
                            fill: $contrastWhite;
                        }
                    }
                    p {
                        color: $contrastPrimary;
                    }
                }
            }
        }
    }
}