.ghost-menu {
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    max-width: 330px;
    min-width: 330px;
    transition: .3s;
    @media( max-width: 1300px ) {
        position: fixed;
    }
    &.minimalize {
        max-width: 55px;
        min-width: 55px;
    }
}
.menu {
    position: fixed;
    top: 0;
	height: 100vh;
	max-width: 330px;
	width: 100%;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	transition: .3s;
    z-index: 30;
    background: rgb(35,66,123);
    background: -moz-linear-gradient(270deg, $navbarBackgroundGradientColor 0%, $navbarBackgroundColor 20%);
    background: -webkit-linear-gradient(270deg, $navbarBackgroundGradientColor 0%, $navbarBackgroundColor 20%);
    background: linear-gradient(270deg, $navbarBackgroundGradientColor 0%, $navbarBackgroundColor 20%);
    &.zIndex {
        z-index: 0;
    }
    &.minimalize {
        max-width: 55px;
        .menu-wrapper {
            .slide-wrapper-btn {
                .slide-menu {
                    svg {
                        transform: rotate(180deg);
                        left: 1px;
                    }
                }
            }
        }
        .menu-wrapper {
            .slide-wrapper-btn {
                top: 10.5px;
            }
            .client {
                max-height: 55px;
                img {
                    opacity: 0;
                }
            }
            .menu-box {
                height: calc(100vh - 55px);
                ul {
                    li {
                        a {
                            padding: 10px;
                            span {
                                opacity: 0;
                                height: 0;
                            }
                            i {
                                margin: 0;
                            }
                        }
                        &>ul {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
    ::-webkit-scrollbar {
        width: 0.0001px;
    }
    ::-webkit-scrollbar-track {
        background: transparent; 
    }
    ::-webkit-scrollbar-thumb {
        background: transparent; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: transparent; 
    }
    ::-moz-selection {
        background: $scrollbar; 
    }
    ::selection {
        background: $scrollbar; 
    }
    .menu-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        .slide-wrapper-btn {
            position: absolute;
            top: 23px;
            right: 0;
            transform: translateX(50%);
            border: 2px solid transparent;
            &:focus-visible {
                border-color: $navbarFocusColor;
            }
            .slide-menu {
                display: flex;
                justify-content: center;
                align-items: center;
                // position: absolute;
                // top: 23px;
                // right: 0;
                // transform: translateX(50%);
                // background-color: $navbarButtonColor;
                background-color: #dc264d;
                height: 37px;
                width: 37px;
                border-radius: 50%;
                cursor: pointer;
                z-index: 99999;
                svg {
                    position: relative;
                    left: -2px;
                    path {
                        fill: $navbarBackgroundColor;
                    }
                }
            }
        }
        .client {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 155px;
            height: 155px;
            transition: .3s;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                transition: .3s;
            }
        }
        .menu-box {
            height: calc(100vh - 155px);
            overflow-y: auto;
            // direction: rtl;
            ul {
                display: block;
                list-style: none;
                padding-left: 0;
                margin: 0;
                margin-top: 3px;
                li {
                    a {
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding: 18px 20px;
                        color: $navbarFontColor;
                        font-size: 1rem;
                        transition: .2s;
                        border-top: 1px solid $navbarBorderColor;
                        border-right: 2px solid transparent;
                        border-bottom: 2px solid transparent;
                        border-left: 2px solid transparent;
                        &:focus-visible {
                            border: 2px solid $navbarFocusColor;
                        }
                        &:hover {
                            color: $navbarFontColorHover;
                        }
                        &.submenu {
                            &::after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: -2px;
                                width: 0; 
                                height: 0; 
                                border-top: 12px solid transparent;
                                border-bottom: 12px solid transparent; 
                                border-right:12px solid $background; 
                            }
                        }
                        i {
                            margin-right: 20px;
                            max-width: 30px;
                        }
                    }
                    &.active {
                        &>a {
                            color: $navbarFontColorHover;
                        }
                    }
                    // &:hover, &:focus-within, &.active {
                    &:focus-within, &.active {
                        & > ul  { 
                            transform: translateX(0);
                            max-height: 1000px;
                        }
                    }
                    &>ul {
                        transform: translateX(-300px);
                        transition: .8s;
                        padding-left: 20px;
                        // transition-delay: 0.1s;
                        min-height: 0;
                        max-height: 0;
                        li {
                            &:first-of-type {
                                a {
                                    padding-top: 0;
                                }
                            }
                            a {
                                // border: none !important;
                                border: none;
                            }
                            &.active {
                                color: $navbarFontColorHover;
                            }
                        }
                    }
                }
            }
        }
    }
}

.contrast-wcag {
    .menu {
        background: $contrastPrimary;
        background: -moz-linear-gradient(270deg, $contrastNavbarBackgroundGradientColor 0%, $contrastPrimary 20%);
        background: -webkit-linear-gradient(270deg, $contrastNavbarBackgroundGradientColor 0%, $contrastPrimary 20%);
        background: linear-gradient(270deg, $contrastNavbarBackgroundGradientColor 0%, $contrastPrimary 20%);
        .menu-wrapper {
            .client {
                svg {
                    rect {
                        fill: #000000;
                    }
                    path {
                        fill: #ffffff;
                    }
                }
            }
            .menu-box {
                ul {
                    li {
                        &.active {
                            &>a {
                                font-weight: bold;
                            }
                        }
                        a {
                            color: $contrastSecondary;
                            border-top: 1px solid $contrastSecondary;
                            &:focus-visible {
                                border: 2px solid $contrastSecondary;
                            }
                            &.submenu {
                                &::after {
                                    border-right: 12px solid $contrastSecondary;
                                }
                            }
                        }
                        &>ul {
                            li {
                                a {
                                    border: none;
                                    &:focus-visible {
                                        border: 2px solid $contrastSecondary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .slide-menu {
                background-color: $contrastWhite;
                svg {
                    path {
                        fill: $contrastSecondary;
                    }
                }
            }
        }
    }
}