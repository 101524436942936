.icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    .icon-box {
        padding: 5px;
        margin: 5px;
        // border: 1px solid $mediaLoaderBorderColor;
        cursor: pointer;
        max-width: 70px;
        max-height: 70px;
        width: 100%;
        height: 100%;
        &.active {
            // border-color: $mediaLoaderBorderSelect;
            border: 5px solid $mediaLoaderBorderColor;
        }
    }
}

.contrast-wcag {
    .icons-wrapper {
        .icon-box {
            background-color: #ffffff;
            &.active {
                border-color: $contrastPrimary;
            }
        }
    }
}