.title {
    border-bottom: 1px solid $titleBorderColor;
    padding-bottom: 50px;
    margin-bottom: 50px;
    @media( max-width: 1000px ) {
        padding-bottom: 20px;
        margin-bottom: 30px;
    }
    h1 {
        color: $titleContentFontColor;
        font-size: 1.5rem;
    }
}