.login-wrapper {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: url('./../../images/bg-login.jpg');
    background: url('./../../images/svg/bg-qr-code.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1000px;
    // background-size: cover;
    
    .overlay {
        // background-color: rgba(255,255,255, .75);
        // background-color: rgba(44, 82, 153, .8);
        background-color: rgba(0,0,0, .35);
        width           : 100%;
        min-height      : 100%;
        position        : absolute;
        z-index         : 1;
        left            : 0;
        top             : 0;
    }
    img {
        position: relative;
        z-index: 2;
        width: 200px;
        margin-top: 20px;
    }
    .logo {
        z-index: 2;
        svg {
            path {
                fill: #000000 !important;
            }
        }
    }
    .login {
        margin-top: 30px;
        max-width: 400px;
        width: 100%;
        padding: 30px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        background-color: rgba(255,255,255, 1);
        margin-bottom: 20px;
        h1 {
            margin-bottom: 30px;
            text-align: center;
            font-size: 1.125rem;
            color: $secondaryColor;
        }
        .welcome {
            margin-bottom: 15px;
            text-align: center;
            font-size: 44px;
            font-weight: normal;
            color: $secondaryColor;
            @media( max-width: 1024px ) {
                font-size: 32px;
            }
            strong {
                font-weight: bold;
                color: #dc264d;
            }
        }
        img {
            margin: 0 auto;
            max-height: 120px;
            object-fit: contain;
        }
        .form-login, .form-send-reset-password, .form-reset-password {
            .input-wrapper-component {
                margin-bottom: 15px;
            }
            .btn-wrap {
                width: 100%;
            }
            .loader-wrapper-inline {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-left: 0;
            }
        }
        .navigation {
            margin-top: 15px;
            small {
                color: $primaryFontColor;
                a {
                    color: $secondaryColor;
                    &:focus-visible {
                        border: 2px solid $focusColor;
                    }
                }
            }
        }
    }
}

.contrast-wcag {
    .login-wrapper {
        .overlay {
            
        }
        .login {
            background-color: rgba(0, 0, 0, 0.8);
            .form-login, .form-send-reset-password, .form-reset-password {
                h1 {
                    color: $contrastWhite;
                }
                form {
                    .input-wrapper-component {
                        .input-box {
                            border: none;
                            &:focus-visible, &:focus-within {
                                border: none
                            }
                        }
                    }
                }
                .navigation {
                    small {
                        color: $contrastWhite;
                        a {
                            color: $contrastPrimary;
                            &:focus-visible {
                                border-color: $contrastWhite;
                            }
                        }
                    }
                }
            }
        }
    }
}